"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const web_ui_1 = require("@gibme/web-ui");
class API {
    /**
     * Returns standard headers for the requests and includes the bearer token if we have it
     * @protected
     */
    static headers() {
        const headers = new web_ui_1.Headers();
        if (API.token) {
            headers.set('Authorization', `Bearer ${API.token}`);
        }
        return headers;
    }
    /**
     * Returns if this instance is logged in
     */
    static get isLoggedIn() {
        return !!API.token;
    }
    /**
     * Attempts to register a mobile device for SMS OTP delivery
     * @param record
     */
    static clientRegister(record) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield web_ui_1.fetch.patch(`${API.base_url}/client/register`, {
                headers: API.headers(),
                json: record
            });
            return response.status;
        });
    }
    /**
     * Attempts to log a client in to the API
     * @param record
     */
    static clientLogin(record) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield web_ui_1.fetch.post(`${API.base_url}/client/login`, {
                headers: API.headers(),
                json: record
            });
            let result;
            if (response.ok) {
                const { token, first_name, last_name } = yield response.json();
                result = {
                    first_name,
                    last_name
                };
                API.token = token;
            }
            return [response.status, result];
        });
    }
    /**
     * Attempts to log staff into the API
     * @param email
     * @param password
     */
    static staffLogin(email, password) {
        return __awaiter(this, void 0, void 0, function* () {
            const json = {
                email,
                password
            };
            const response = yield web_ui_1.fetch.post(`${API.base_url}/staff/login`, {
                headers: API.headers(),
                json
            });
            if (response.ok) {
                const { token } = yield response.json();
                API.token = token;
            }
            return response.status;
        });
    }
    /**
     * Attempts to fetch a list of clients from the API
     */
    static listClients() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield web_ui_1.fetch.get(`${API.base_url}/client/list`, {
                headers: API.headers()
            });
            if (response.ok) {
                return response.json();
            }
            return [];
        });
    }
    /**
     * Attempts to list the files for the specified client ID from the API
     * @param cid
     */
    static listFiles(cid) {
        return __awaiter(this, void 0, void 0, function* () {
            let response;
            if (cid) {
                response = yield web_ui_1.fetch.get(`${API.base_url}/download/list/${cid}`, {
                    headers: API.headers()
                });
            }
            else {
                response = yield web_ui_1.fetch.get(`${API.base_url}/download/list`, {
                    headers: API.headers()
                });
            }
            if (response.ok) {
                return response.json();
            }
            return [];
        });
    }
    /**
     * Deletes a file from the API
     * @param record
     */
    static deleteFile(record) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield web_ui_1.fetch.delete(`${API.base_url}/download/${record.client_id}/${record.year}/${record.type}`, {
                headers: API.headers()
            });
            return response.ok;
        });
    }
    /**
     * Deletes a client uploaded file
     * @param record
     */
    static deleteClientFile(record) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield web_ui_1.fetch.delete(`${API.base_url}/upload/${record.id}`, {
                headers: API.headers()
            });
            return response.ok;
        });
    }
    /**
     * Uploads a file to the API
     * @param record
     */
    static uploadFile(record) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield web_ui_1.fetch.put(`${API.base_url}/download`, {
                headers: API.headers(),
                json: record
            });
            return response.status;
        });
    }
    /**
     * Uploads a client provided file to the API
     * @param record
     */
    static uploadClientFile(record) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield web_ui_1.fetch.put(`${API.base_url}/upload`, {
                headers: API.headers(),
                json: record
            });
            return response.status;
        });
    }
    /**
     * Downloads a file from the API
     * @param record
     */
    static downloadFile(record) {
        return __awaiter(this, void 0, void 0, function* () {
            window.open(`${API.base_url}/download/${record.client_id}/${record.year}/${record.type}?token=${API.token}`);
        });
    }
    /**
     * Downloads a client provided file from the API
     * @param record
     */
    static downloadClientFile(record) {
        return __awaiter(this, void 0, void 0, function* () {
            window.open(`${API.base_url}/upload/${record.id}?token=${API.token}`);
        });
    }
    /**
     * Pings the API to determine if our session is still good
     */
    static ping() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield web_ui_1.fetch.get(`${API.base_url}/ping`, {
                headers: API.headers()
            });
            return response.ok;
        });
    }
    /**
     * Attempts to update a client record via the API
     * @param record
     */
    static updateClient(record) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield web_ui_1.fetch.put(`${API.base_url}/client`, {
                headers: API.headers(),
                json: record
            });
            return response.status;
        });
    }
    /**
     * Logs out of the API
     */
    static logout() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield web_ui_1.fetch.delete(`${API.base_url}/login`, {
                    headers: API.headers()
                });
            }
            finally {
                if (API.token) {
                    delete API.token;
                }
            }
        });
    }
    /**
     * Attempts to retrieve a list of client provided uploads from the API
     * @param id
     */
    static listClientUploads(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = id ? `${API.base_url}/upload/${id}/list` : `${API.base_url}/upload/list`;
            const response = yield web_ui_1.fetch.get(url, {
                headers: API.headers()
            });
            if (response.ok) {
                const records = yield response.json();
                return records.map(elem => {
                    elem.timestamp = new Date(elem.timestamp);
                    return elem;
                });
            }
            return [];
        });
    }
}
API.base_url = 'https://returns-api.hollandcpa.cc';
exports.default = API;
