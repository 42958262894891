"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hash = exports.new_hash = exports.old_hash = void 0;
const crypto_1 = require("crypto");
/**
 * Old digest computation
 * @param value
 */
const old_hash = (value) => {
    return (0, crypto_1.createHash)('sha1')
        .update(value)
        .digest('hex');
};
exports.old_hash = old_hash;
/**
 * New digest computation
 * @param value
 * @param salt
 */
const new_hash = (value, salt = value) => {
    return (0, crypto_1.createHash)('sha512')
        .update(value)
        .update(salt)
        .digest('hex');
};
exports.new_hash = new_hash;
/**
 * General digest computation
 * @param args
 */
const hash = (...args) => {
    return (0, crypto_1.createHash)('sha1')
        .update(JSON.stringify(args))
        .digest('hex');
};
exports.hash = hash;
