"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HTTPError = exports.FieldMap = exports.FileTypes = exports.FileTypeName = exports.FileType = void 0;
var FileType;
(function (FileType) {
    FileType[FileType["Return"] = 1] = "Return";
    FileType[FileType["W2"] = 2] = "W2";
    FileType[FileType["Organizer"] = 3] = "Organizer";
})(FileType || (exports.FileType = FileType = {}));
const FileTypeName = (value) => {
    switch (value) {
        case FileType.Organizer:
            return 'Organizer';
        case FileType.W2:
            return 'Support';
        case FileType.Return:
            return 'Return';
    }
};
exports.FileTypeName = FileTypeName;
exports.FileTypes = (() => {
    const cells = Object.values(FileType);
    const result = new Map();
    for (let i = cells.length / 2; i < cells.length; i++) {
        const key = cells[i];
        result.set(key, (0, exports.FileTypeName)(key));
    }
    return result;
})();
var FieldMap;
(function (FieldMap) {
    FieldMap.Client = [
        'cID as id',
        'cLastname AS last_name',
        'cFirstname AS first_name',
        'cSSN AS ssn',
        'cBirthday AS birthday',
        'cMobile AS mobile'
    ].join(',');
    FieldMap.ConfigParam = [
        'cpID as id',
        'cpParam as param',
        'cpValue as value'
    ];
    FieldMap.Staff = [
        'sID as id',
        'sEmail as email',
        'sPassword as password'
    ];
    FieldMap.Return = [
        'cID as client_id',
        'rYear as year',
        'rFilename as filename',
        'sID as staff_id',
        'rTimestamp as timestamp',
        `${FileType.Return} as type`
    ];
    FieldMap.W2 = [
        'cID as client_id',
        'wYear as year',
        'wFilename as filename',
        'sID as staff_id',
        'wTimestamp as timestamp',
        `${FileType.W2} as type`
    ];
    FieldMap.Organizer = [
        'cID as client_id',
        'oYear as year',
        'oFilename as filename',
        'sID as staff_id',
        'oTimestamp as timestamp',
        `${FileType.Organizer} as type`
    ];
})(FieldMap || (exports.FieldMap = FieldMap = {}));
var HTTPError;
(function (HTTPError) {
    HTTPError[HTTPError["OK"] = 200] = "OK";
    HTTPError[HTTPError["CREATED"] = 201] = "CREATED";
    HTTPError[HTTPError["BAD_REQUEST"] = 400] = "BAD_REQUEST";
    HTTPError[HTTPError["UNAUTHORIZED"] = 401] = "UNAUTHORIZED";
    HTTPError[HTTPError["FORBIDDEN"] = 403] = "FORBIDDEN";
    HTTPError[HTTPError["NOT_FOUND"] = 404] = "NOT_FOUND";
    HTTPError[HTTPError["CONFLICT"] = 409] = "CONFLICT";
    HTTPError[HTTPError["PRECONDITION_FAILED"] = 412] = "PRECONDITION_FAILED";
    HTTPError[HTTPError["PRECONDITION_REQUIRED"] = 428] = "PRECONDITION_REQUIRED";
    HTTPError[HTTPError["MOBILE_REQUIRED"] = 490] = "MOBILE_REQUIRED";
    HTTPError[HTTPError["OTP_REQUIRED"] = 491] = "OTP_REQUIRED";
    HTTPError[HTTPError["VERIFY_OTP"] = 492] = "VERIFY_OTP";
    HTTPError[HTTPError["SERVER_ERROR"] = 500] = "SERVER_ERROR";
})(HTTPError || (exports.HTTPError = HTTPError = {}));
