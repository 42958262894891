"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const web_ui_1 = require("@gibme/web-ui");
const types_1 = require("../types");
const common_1 = require("./common");
(0, web_ui_1.$)(document).ready(() => {
    (0, web_ui_1.createDataTable)('#client_file_list', {
        paging: false,
        searching: false,
        responsive: true,
        language: {
            emptyTable: 'No files are currently available for you.'
        },
        columnDefs: [
            {
                targets: [0],
                className: 'text-center w-header-sm',
                orderable: false,
                render: (record) => {
                    return record;
                }
            },
            {
                targets: [1],
                orderable: false,
                render: (record, type) => {
                    if (type === 'display') {
                        return (0, types_1.FileTypeName)(record);
                    }
                    return record.valueOf();
                }
            },
            {
                targets: [2],
                className: 'text-center w-button-lg',
                orderable: false,
                searchable: false,
                render: (record, type) => {
                    if (type === 'display') {
                        const button = (0, web_ui_1.createAwesomeButton)('file-arrow-down', {
                            label: 'Download'
                        })
                            .addClass('btn btn-sm btn-success')
                            .attr('id', `download_${record.client_id}${record.type}${record.year}`);
                        return (0, web_ui_1.fetchHTML)(button);
                    }
                    return '';
                }
            }
        ],
        order: [[0, 'desc'], [1, 'asc']]
    }).draw()
        .columns.adjust()
        .responsive.recalc();
    (0, web_ui_1.createDataTable)('#client_info_list', {
        paging: true,
        searching: true,
        pageLength: 25,
        responsive: true,
        deferRender: true,
        language: {
            emptyTable: 'There are no clients available...'
        },
        columnDefs: [
            {
                targets: [2],
                render: (record, type) => {
                    if (type === 'display') {
                        return (0, common_1.formatPhone)(record);
                    }
                    return record;
                }
            },
            {
                targets: [3],
                searchable: false,
                orderable: true,
                render: (record, type) => {
                    if (type === 'display' || type === 'filter') {
                        return web_ui_1.moment.utc(record).format('MM/DD/YYYY');
                    }
                    return web_ui_1.moment.utc(record).unix();
                }
            },
            {
                targets: [4],
                className: 'text-center w-button',
                orderable: false,
                searchable: false,
                render: (record, type) => {
                    if (type === 'display') {
                        return (0, web_ui_1.fetchHTML)((0, web_ui_1.createAwesomeButton)('pen-to-square')
                            .addClass('btn btn-sm btn-primary')
                            .attr('onclick', `handle_client_edit(${record.id})`));
                    }
                    return '';
                }
            }
        ],
        order: [[0, 'asc'], [1, 'asc']]
    }).draw()
        .columns.adjust()
        .responsive.recalc();
    (0, web_ui_1.createDataTable)((0, web_ui_1.$)('#client_uploaded_file_list'), {
        paging: false,
        searching: true,
        language: {
            emptyTable: 'No files have been uploaded by you'
        },
        columnDefs: [
            {
                targets: [0],
                render: (record) => {
                    return record;
                }
            },
            {
                targets: [1],
                className: 'w-button-lg',
                render: (record, type) => {
                    if (type === 'display' || type === 'filter') {
                        return (0, web_ui_1.moment)(record).format('MM/DD/YYYY');
                    }
                    return (0, web_ui_1.moment)(record).unix();
                }
            },
            {
                targets: [2],
                orderable: false,
                searchable: false,
                className: 'text-center w-button-lg',
                render: (record, type) => {
                    if (type === 'display') {
                        const button = (0, web_ui_1.createAwesomeButton)('file-arrow-down', {
                            label: 'Download'
                        })
                            .addClass('btn btn-sm btn-success')
                            .attr('id', `client_upload_${record.id}`);
                        return (0, web_ui_1.fetchHTML)(button);
                    }
                    return '';
                }
            }
        ],
        order: [[1, 'desc']]
    }).draw()
        .columns.adjust()
        .responsive.recalc();
});
